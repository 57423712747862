@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { 
    height: 100%
}

.bgbrown {
    width: 100%;
    background-color: #2980b9;
}

.bgGray {
    background-color: #3867d6;
}

.bgContainer{
    background-color: #F9F9F9;
}

body{
    margin: 0;
    background-image: url("../public/img/background.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100%;
}